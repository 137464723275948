import { useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import VaultsData from "hpay/content/Vaults.json";
import FlashStakingVault from "../components/vaults/flash-staking/vault";
import TimeLockVault from "../components/vaults/time-lock/vault";
import SimpleVault from "../components/vaults/simple-staking/vault";

function Vault() {
    const params = useParams();
    const [vault, setVault] = useState();

    useEffect(() => {
        setVault(VaultsData[params.vaultAddress]);
    }, [params]);

    return <>
        {
            vault && vault.type === 'FLASH_STAKING' && <FlashStakingVault></FlashStakingVault>
        }
        {
            vault && vault.type === 'TIME_LOCK' && <TimeLockVault></TimeLockVault>
        }
        {
            vault && vault.type === 'SIMPLE_STAKING' && <SimpleVault></SimpleVault>
        }
    </>;
}

export default Vault;
