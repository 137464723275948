import VaultListJson from "hpay/content/Vaults.json";
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import FlashStakingVault from '../components/vaults/flash-staking/vault-preview';
import SimpleVault from '../components/vaults/simple-staking/vault-preview';
import TimeLockStakingVault from '../components/vaults/time-lock/vault-preview';

const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);

    return { isClient, key };
};

function Vaults() {
    const { isClient } = useIsClient();

    if (!isClient) { return null; }
    return (
        <div className="">
            <Row className="mb-2 ">
                <Col md={12} className="mb-md-5 mb-4">
                    <div className="reward-claim-component">
                        <Row className="mb-lg-5 mb-4 reward-header">
                            <Col md={8} lg={9}>
                                <h2 className="title-seperator">HedgePay Vaults</h2>
                                <p>
                                    HedgePay offers off the shelf staking as a service for any ERC20 compliant token.
                                </p>
                            </Col>
                            <Col md={4} lg={3} className="text-center mt-md-0 mt-5 text-md-right bounty-widget d-flex flex-column align-items-end">
                                <small className="farming-stats-value-label mt-auto ">Get your vault now</small>

                                <Button
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://k13pavxdut8.typeform.com/to/Bnqj8V68"
                                    className="btn text-white mt-4 ">Request a Vault</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <VaultList />
            </Row>
        </div >
    );
}


const VaultList = () => {
    const [vaults, setVaults] = useState([]);

    useEffect(() => {
        const result = [];
        for (const [key, value] of Object.entries(VaultListJson)) {
            if (!value.enabled) {
                continue;
            }

            result.push({ ...value, location: key });
        }
        setVaults(result);
    }, [VaultListJson, setVaults]);

    return <Row className='w-100 '>
        {
            vaults.map((vault, index) => (
                <Col key={index} lg={6} xl={4} className="mb-4 vault">
                    {vault.type === 'FLASH_STAKING' && <FlashStakingVault {...vault}></FlashStakingVault>}
                    {vault.type === 'TIME_LOCK' && <TimeLockStakingVault  {...vault}></TimeLockStakingVault>}
                    {vault.type === 'SIMPLE_STAKING' && <SimpleVault  {...vault}></SimpleVault>}

                </Col>)
            )
        }
    </Row>;
};

export default Vaults;
