
import { Link } from 'gatsby';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import VaultsData from "hpay/content/Vaults.json";
import NetworkContext from '../../../context/network-context';
import { useCurrentVault, useUserVaultAmounts } from '../../../state/flash-staking';
import { fetchTokenBalance } from '../../../web3/account';
import { useVaultActions } from '../../../web3/flash-staking';
import VaultInput from "./vault-input";
import { useVaultAddress } from '../../../utils/utils';

function VaultStats({ address }) {
    const vaultAddress = useVaultAddress(address);

    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const [showStakingModal, setShowStakingModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(null);

    const { currentVault: vaultData } = useCurrentVault(vaultAddress);
    const { lock, unlock, claim } = useVaultActions(account, vaultAddress);
    const { userLocked, userUnlocked, userEarned, userTotalEarned } = useUserVaultAmounts(vaultAddress);

    const handleClose = () => setShowStakingModal(false);
    const [vault, setVault] = useState(null);
    const [roundFill, setRoundFill] = useState(0);
    const [status, setStatus] = useState(0);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        if (!vaultData) {
            return;
        }
        setRoundFill((vaultData.totalLocked * 100) / vaultData.maxLock);
        setStatus(vaultData.status);
    }, [vaultData]);


    useEffect(() => {
        setVault(VaultsData[address]);
    }, [address]);


    const fetchBalance = useCallback(async () => {
        if (!vaultData || !vaultData.stakeToken) {
            return;
        }


        const balance = await fetchTokenBalance(vaultData.stakeToken, account);
        setBalance(balance || 0);
    }, [setBalance, vault, account]);


    useEffect(() => {
        if (!account || !connected) {
            return;
        }
        fetchBalance();
    }, [account, connected, vault]);


    const handleStake = useCallback(() => {
        console.log('here', vault);
        if (!vault) {
            return;
        }

        setModalStatus({
            mode: 'stake',
            balance,
            action: async (amount) => {
                const tx = async () => await lock(amount);
                try {
                    await executeTransaction({
                        message: 'Staking',
                        tx
                    });

                    await fetchBalance();
                } catch (error) {
                    console.log(error);
                }

                setShowStakingModal(false);
            }
        });

        setShowStakingModal(true);
    }, [setModalStatus, setShowStakingModal, executeTransaction, lock, fetchBalance, vault, balance]);

    const handleUnstake = useCallback(async () => {

        if (!vault) {
            return;
        }

        setModalStatus({
            mode: 'unstake',
            balance,
            withdrawTax: vaultData.penalty,
            action: async () => {
                const tx = async () => {
                    return await unlock();
                };
                try {
                    await executeTransaction({
                        message: 'Withdrawing',
                        tx
                    });
                    await fetchBalance();
                } catch (error) {
                    console.log(error);
                }
                setShowStakingModal(false);
            }
        });

        setShowStakingModal(true);

    }, [executeTransaction, unlock, fetchBalance]);

    const handleClaim = useCallback(async () => {
        const tx = async () => {
            return await claim();
        };
        try {
            await executeTransaction({
                message: 'Claiming',
                tx
            });

            await fetchBalance();
        } catch (error) {
            console.log(error);
        }
    }, [claim, executeTransaction, fetchBalance, vault]);

    return (
        <>
            <div className="staking-component">
                <Card>
                    <Card.Body>
                        <div>
                            <Row noGutters>
                                <Col sm={4} md={12} lg={7}
                                    className="farming-stats-group text-sm-right text-lg-right text-md-center text-center order-md-0 order-sm-2 order-lg-2 ">
                                    <Button
                                        onClick={handleClaim}
                                        disabled={!connected || !isBsc || !!pendingTransaction || userEarned === 0}
                                        className="text-white">Claim</Button>
                                </Col>

                                <Col sm={8} md={12} lg={5} className="farming-stats-group text-left">
                                    <small className="farming-stats-value-label">You Earned</small>
                                    <p className="farming-stats-value-item mb-0">
                                        <CountUp
                                            isCounting end={userEarned || 0}
                                            decimals={2} separator=',' /> {vault && vault.ticker}
                                    </p>
                                    <small>will unlock <span className="text-primary-gradient" > <CountUp

                                        isCounting end={userUnlocked || 0}
                                        decimals={2} separator=',' /> {vault && vault.ticker}
                                    </span> </small>
                                </Col>
                            </Row>



                            <Row noGutters>
                                <Col className="farming-stats-group text-left">
                                    <small className="farming-stats-value-label">Total {vault && vault.ticker} Locked</small>
                                    <p className="farming-stats-value-item mb-0 text-primary-gradient" >
                                        <CountUp isCounting end={(vaultData && vaultData.totalLocked) || 0} decimals={0} separator=',' />
                                    </p>
                                </Col>

                                <Col className="farming-stats-group text-right">
                                    <small className="farming-stats-value-label">Total {vault && vault.ticker} Rewards</small>
                                    <p className="farming-stats-value-item text-primary-gradient">
                                        <CountUp isCounting end={(vaultData && vaultData.totalRewardFund) || 0} decimals={0} separator=',' />
                                    </p>
                                </Col>
                            </Row>


                            <Row noGutters>
                                <Col className="farming-stats-group text-left">
                                    <small className="farming-stats-value-label">You earned a total of </small>
                                    <p className="farming-stats-value-item mb-0">
                                        <CountUp isCounting end={userTotalEarned || 0} decimals={0} separator=',' />   {vault && vault.ticker}

                                    </p>
                                </Col>

                                <Col className="farming-stats-group text-right">
                                    <small className="farming-stats-value-label"> Early Unlock Penalty </small>
                                    <p className="farming-stats-value-item">
                                        <small> %</small> <CountUp isCounting end={(vaultData && vaultData.penalty) || 0} decimals={2} separator=',' />
                                    </p>
                                </Col>

                            </Row>

                            <Row className="justify-content-between align-items-center mt-2">
                                <Col sm={6} className="mb-sm-0 mb-3">
                                    <Button
                                        onClick={handleStake}
                                        disabled={status !== 1 || roundFill >= 100 || !connected || !isBsc || !!pendingTransaction}
                                        className="text-white" block>Lock</Button>
                                </Col>
                                <Col sm={6} >
                                    <Button
                                        onClick={handleUnstake}
                                        disabled={status === 0 || !connected || !isBsc || !!pendingTransaction || userLocked == 0}
                                        className="text-white" block> Unlock </Button>
                                </Col>

                            </Row>
                        </div>
                    </Card.Body>
                </Card >
                <Col md={12} className='text-center mb-2 mt-2'>
                    <small>  * Hold <Link target='_blank' to="/invest/">
                        <span className='text-primary-gradient font-weight-bold'> HPAY </span>
                    </Link> in your wallet to and get a 0.5% staking tax discount.</small>
                </Col>
            </div>

            <Modal
                className="stake-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={!!showStakingModal} onHide={handleClose} >

                <Modal.Body>
                    <VaultInput userLocked={userLocked} vault={vaultData} {...modalStatus}></VaultInput>
                </Modal.Body>

            </Modal>

        </>
    );
}

export default VaultStats;
