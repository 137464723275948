
import VaultsData from "hpay/content/Vaults.json";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import NetworkContext from '../../../context/network-context';
import { useCurrentVault, useUserVaultAmounts } from '../../../state/simple-staking';
import { useVaultAddress } from '../../../utils/utils';
import { fetchTokenBalance } from '../../../web3/account';
import { useVaultActions } from '../../../web3/simple-staking';
import VaultInput from "./simple-vault-input";

function VaultStats({ address }) {
    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const [showStakingModal, setShowStakingModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(null);
    const vaultAddress = useVaultAddress();

    const { currentVault: vaultData } = useCurrentVault(vaultAddress);
    const { lock, unlock, claim, compound } = useVaultActions(account, vaultAddress);
    const { userLocked, userEarned, userUnlockTime, userEarnedReflection } = useUserVaultAmounts(vaultAddress);

    const handleClose = () => {

        if (pendingTransaction) {
            return;
        }
        setShowStakingModal(false);
    };
    const [vault, setVault] = useState(null);
    const [allowEarlyUnlock, setAllowEarlyUnlock] = useState(false);

    const [balance, setBalance] = useState(0);

    useEffect(() => {
        if (!vaultData) {
            return;
        }

        setAllowEarlyUnlock(vaultData.allowEarlyUnlock);
    }, [vaultData]);


    useEffect(() => {
        setVault(VaultsData[address]);
    }, [vaultAddress]);


    const fetchBalance = useCallback(async () => {
        if (!vaultData) {
            return;
        }

        const balance = await fetchTokenBalance(vaultData.stakeToken, account);
        setBalance(balance || 0);
    }, [setBalance, vaultData, account]);


    useEffect(() => {
        if (!account || !connected) {
            return;
        }
        fetchBalance();
    }, [account, connected, vault]);


    const handleStake = useCallback(() => {
        if (!vault) {
            return;
        }

        setModalStatus({
            mode: 'stake',
            balance,
            action: async (amount) => {
                const tx = async () => await lock(amount);
                try {
                    await executeTransaction({
                        message: 'Staking',
                        tx
                    });

                    await fetchBalance();
                } catch (error) {
                    console.log(error);
                }

                setShowStakingModal(false);
            }
        });

        setShowStakingModal(true);
    }, [setModalStatus, setShowStakingModal, executeTransaction, lock, fetchBalance, vault, balance]);

    const handleUnstake = useCallback(() => {
        setModalStatus({
            mode: 'unstake',
            balance: userLocked,
            userUnlockTime,
            action: async (amount) => {
                const tx = async () => {
                    return await unlock(amount);
                };
                try {
                    await executeTransaction({
                        message: 'Withdrawing',
                        tx
                    });

                    await fetchBalance();

                } catch (error) {
                    console.log(error);
                }

                setShowStakingModal(false);
            }
        });

        setShowStakingModal(true);
    }, [setModalStatus, setShowStakingModal, unlock, executeTransaction, fetchBalance]);

    const handleCompound = useCallback(async () => {
        const tx = async () => {
            return await compound();
        };
        try {
            await executeTransaction({
                message: 'Compunding',
                tx
            });

            await fetchBalance();
        } catch (error) {
            console.log(error);
        }
    }, [compound, executeTransaction, fetchBalance, vault]);

    const handleClaim = useCallback(async () => {
        const tx = async () => {
            return await claim();
        };
        try {
            await executeTransaction({
                message: 'Claiming',
                tx
            });

            await fetchBalance();
        } catch (error) {
            console.log(error);
        }
    }, [claim, executeTransaction, fetchBalance, vault]);

    return (
        <>
            <div className="staking-component h-100">
                <Card>
                    <Card.Body>
                        <div>
                            <Row noGutters>
                                <Col sm={6} md={12} lg={6}
                                    className="farming-stats-group  order-md-0 order-sm-2 order-lg-2 d-flex justify-content-end">

                                    <div>
                                        <Button
                                            onClick={handleClaim}
                                            disabled={!connected || !isBsc || !!pendingTransaction || userEarned === 0}
                                            className="text-white">Claim</Button>
                                    </div>
                                    {vault && vault.compound && <div className='ml-2'>
                                        <Button
                                            onClick={handleCompound}
                                            disabled={!connected || !isBsc || !!pendingTransaction || userEarned === 0}
                                            className="text-white">Compound</Button>
                                    </div>}

                                </Col>

                                <Col sm={6} md={12} lg={6} className="farming-stats-group text-left ">
                                    <small className="farming-stats-value-label">You Earned</small>
                                    <p className="farming-stats-value-item mb-0">
                                        <CountUp
                                            isCounting end={userEarned || 0}
                                            decimals={2} preserveValue={true} separator=',' /> {vault && vault.ticker}
                                    </p>
                                    {vault && vault.reflections && connected && <small className="text-primary-gradient">+ {userEarnedReflection && userEarnedReflection.toFixed(2)} {vault.reflections.ticker} Reflections</small>}
                                </Col>
                            </Row>



                            <Row noGutters>
                                <Col className="farming-stats-group text-left">
                                    <small className="farming-stats-value-label">Total {vault && vault.ticker} Locked</small>
                                    <p className="farming-stats-value-item mb-0 text-primary-gradient" >
                                        <CountUp isCounting end={(vaultData && vaultData.totalLocked) || 0} decimals={2} preserveValue={true} separator=',' />
                                    </p>
                                </Col>

                                <Col className="farming-stats-group text-right">
                                    <small className="farming-stats-value-label">Total {vault && vault.ticker} Rewards</small>
                                    <p className="farming-stats-value-item text-primary-gradient">
                                        <CountUp isCounting end={(vaultData && vaultData.totalRewardFund) || 0} decimals={2} preserveValue={true} separator=',' />
                                    </p>
                                </Col>
                            </Row>



                            <Row className="justify-content-between align-items-center mt-2">
                                <Col sm={6} className="mb-sm-0 mb-3">
                                    <Button
                                        onClick={handleStake}
                                        disabled={!connected || !isBsc || !!pendingTransaction || !vaultData}
                                        className="text-white" block>Lock</Button>
                                </Col>
                                <Col sm={6} >
                                    <Button
                                        onClick={handleUnstake}
                                        disabled={!connected || !isBsc || !!pendingTransaction || userLocked == 0}
                                        className="text-white" block> Unlock </Button>
                                </Col>

                            </Row>
                        </div>
                    </Card.Body>
                </Card >

            </div>

            <Modal
                className="stake-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={!!showStakingModal} onHide={handleClose} >

                <Modal.Body>
                    <VaultInput userLocked={userLocked} vault={vaultData} {...modalStatus}></VaultInput>
                </Modal.Body>

            </Modal>

        </>
    );
}

export default VaultStats;
