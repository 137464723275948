import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import NetworkContext from '../../../../context/network-context';
import { useCurrentVault } from '../../../../state/timelock-staking';
import { useVaultAddress } from '../../../../utils/utils';
import { useVaultAdminActions } from '../../../../web3/timelock-staking';

function VaultWhitelist() {
    const vaultAddress = useVaultAddress();
    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);

    const [list, setList] = useState([]);
    const { addToWhitelist, removeFromWhitelist } = useVaultAdminActions(vaultAddress, account);

    const handleAdd = async () => {
        const tx = async () => addToWhitelist(list);

        await executeTransaction({
            message: 'Processing',
            tx
        });
    };

    const handleRemove = async () => {
        const tx = async () => removeFromWhitelist(list);

        await executeTransaction({
            message: 'Processing',
            tx
        });
    };

    const updateList = (e) => {
        const str = e.target.value;
        let list = str.replace(/\s/g, "").split(',');
        if (list[0] === '') {
            list = [];
        }

        list = list.filter(item => window.web3.utils.isAddress(item));
        setList(list);
    };


    return (<>
        <Row style={{ marginBottom: '1.5rem' }} className="align-items-center">
            <Col md={12} className="text-lg-left">
                <h3 className="mb-0 text-white text-center text-lg-left">Manage Whitelist</h3>
            </Col>

            <Col md={12} className="text-lg-left">
                <Form.Group>
                    <p>Insert comma separated addresses</p>
                    <Form.Control as="textarea" rows={10} onChange={updateList} value={list.join(',\n')} placeholder='Address1, Address2' />
                    <p>{list.length}/300 Addresses Inserted</p>
                </Form.Group>
            </Col>

            <Col md={6} className="text-lg-left">
                <Button onClick={handleAdd} disabled={!connected || !isBsc || !!pendingTransaction || list.length >= 300}
                    className="btn btn-block text-white">Add</Button>
            </Col>

            <Col md={6} className="text-lg-left">
                <Button onClick={handleRemove} disabled={!connected || !isBsc || !!pendingTransaction || list.length >= 300}
                    className="btn btn-block text-white">Remove</Button>
            </Col>
        </Row>
    </>


    );
}

export default VaultWhitelist;
