
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import CountUp from 'react-countup';
import VaultsData from "hpay/content/Vaults.json";
import { useCurrentVault, useRefreshCurrentVault, useUserVaultAmounts } from '../../../state/timelock-staking';
import { useVaultAddress } from '../../../utils/utils';
import { Link } from '@reach/router';

const statusMapping = {
    0: "Upcoming",
    1: "Staking Live",
    2: "Staking Live",
    3: "Ended"
};


function VaultInfo({ address }) {
    const vaultAddress = useVaultAddress();
    const { currentVault: vaultData } = useCurrentVault(vaultAddress);
    const { userLocked, userUnlockTime, isUnlocked } = useUserVaultAmounts(vaultAddress);
    const [vault, setVault] = useState(null);
    const [, refreshVault] = useRefreshCurrentVault(address);

    const [status, setStatus] = useState(statusMapping[0]);
    useEffect(() => {
        if (!vaultData) {
            return;
        }
        setStatus(statusMapping[vaultData.status]);
    }, [vaultData]);

    useEffect(() => {
        setVault(VaultsData[address]);
    }, [address]);



    const headerText = <small>
        {
            !vaultData && <>
                Loading...
            </>
        }
        {
            vaultData && vaultData.status === 0 && <>
                <span className="text-primary-gradient">{vault && vault.ticker}</span> time lock staking will start soon
            </>
        }

        {
            vaultData && vaultData.status === 1 && <>
                Lock your <span className="text-primary-gradient">{vault && vault.ticker}</span> to earn
            </>
        }

        {
            vaultData && vaultData.status === 2 && <>
                All staked <span className="text-primary-gradient">{vault && vault.ticker}</span> is locked until round ends
            </>
        }

        {
            vaultData && vaultData.status === 3 && <>
                <span className="text-primary-gradient">{vault && vault.ticker}</span> flash staking has ended
            </>
        }
    </small>;

    const timerText = <small className="farming-stats-value-label">

        {
            !vaultData && <>
                Starts In
            </>
        }
        {
            vaultData && vaultData.status === 0 && <>
                Starts In
            </>
        }

        {
            vaultData && vaultData.status === 1 && <>
                Locks In
            </>
        }

        {
            vaultData && vaultData.status === 2 && <>
                Ends In
            </>
        }

        {
            vaultData && vaultData.status === 3 && <>
                Ended
            </>
        }
    </small>;


    return (
        <div className="reward-claim-component h-100 ">
            <Card>
                <Card.Body className="d-flex flex-column justify-content-between">
                    <div className="d-flex  justify-content-between  " >

                        <div className='farming-stats-group'>
                            <p className="farming-stats-value-item mb-0">Staking Info</p>
                            {
                                headerText
                            }

                        </div>
                        <div >

                            <Badge pill variant={'primary'} >
                                <span className="text-primary-gradient">
                                    {/* {status} */}

                                    Running
                                </span>
                            </Badge>
                        </div>

                    </div>

                    <div className="w-100">
                        <Row noGutters>

                            <Col md={6} className="farming-stats-group text-md-left text-right ">
                                <small className="farming-stats-value-label">You Locked  </small>
                                <p className="farming-stats-value-item mb-0">

                                    <CountUp isCounting end={userLocked || 0} decimals={2} separator=',' /> {vault && vault.ticker}
                                </p>
                            </Col>
                            <Col className="farming-stats-group text-right">
                                <small className="farming-stats-value-label">APR </small>
                                <p className="farming-stats-value-item">
                                    <CountUp isCounting end={(vaultData && vaultData.return) || 0} decimals={0} separator=',' />%
                                </p>
                            </Col>


                        </Row>

                        <Row noGutters>
                            <Col className="farming-stats-group text-left mb-0 ">
                                <small className="farming-stats-value-label">Your Lock Ends In </small>
                                <p className="farming-stats-value-item text-primary-gradient">
                                    {!isUnlocked ? <>
                                        {
                                            !!!userLocked ? "Nothing to unlock" : <Countdown onComplete={() => {
                                                setTimeout(() => {
                                                    refreshVault();
                                                }, 5000);
                                            }} key={userUnlockTime} date={userUnlockTime}></Countdown>
                                        }  </> : <>{
                                            !!!userLocked ? "Nothing to unlock" : "Your locking period has ended"
                                        }
                                    </>
                                    }
                                </p>
                            </Col>

                            <Col className="farming-stats-group  mb-0 text-right">
                                <small className="farming-stats-value-label"> Early Unlock Penalty </small>
                                <p className="farming-stats-value-item">
                                    <CountUp isCounting end={(vaultData && vaultData.penalty) || 0} decimals={2} separator=',' />%
                                </p>
                            </Col>
                        </Row>


                    </div>
                </Card.Body >
            </Card >
        </div >
    );
}

export default VaultInfo;
