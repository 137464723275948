import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Vaults from "../containers/vault-list";
import Vault from "../containers/vault";

import { Router, Link } from "@reach/router";

const IndexPage = () => {

	return (
		<Layout pageInfo={{ pageName: "rewards" }}>
			<ScrollUpButton ContainerClassName="custom-scroll-button" />
			<Seo title="Rewards" />

			<Row noGutters>
				<Col>
					<Container className="mt-5">
						<section id="vaults">
						<Router  basepath="/vaults">
							<Vaults path="/">	</Vaults>
							<Vault path="/:vaultAddress"></Vault>
						</Router>
						</section>
					</Container>
				</Col>
			</Row>


		</Layout>
	);
};

export default IndexPage;
