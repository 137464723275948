import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import NetworkContext from '../../../../context/network-context';
import { fetchTokenBalance } from '../../../../web3/account';
import { useVaultAdminActions } from '../../../../web3/timelock-staking';
import FundingInput from './funding-input';
import Whitelist from './whitelist';

function AdminPanel({ address, ...vault }) {
    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const [openForAll, setOpenForAll] = useState();
    const [started, setStarted] = useState();

    const { setOpenForAll: toggleOpen, topUpVaultRewards, startVault } = useVaultAdminActions(address, account);
    const [showFundingModal, setShowFundingModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(null);
    const handleClose = () => {
        if (!pendingTransaction) {
            setShowFundingModal(false);
        }
    };

    useEffect(() => {
        if (!vault) {
            return;
        }

        if (vault.whitelisted) {
            setOpenForAll(vault.openForAll);
        }

        setStarted(vault.started);

    }, [vault]);


    const toggleOpenForAll = async () => {
        const tx = async () => toggleOpen(!openForAll);

        await executeTransaction({
            message: 'Processing',
            tx
        });
        setOpenForAll(!openForAll);
    };

    const handleStart = useCallback(async () => {
        if (!vault) {
            return;
        }
        try {

            await executeTransaction({
                message: 'Starting Vault',
                tx: startVault
            });

        } catch (error) {
            console.log(error);
        }

    }, [vault, startVault]);

    const handleFunding = useCallback(async () => {
        if (!vault) {
            return;
        }

        const balance = await fetchTokenBalance(vault.stakeToken, account);
        setModalStatus({
            balance,
            action: async (amount) => {
                const tx = async () => topUpVaultRewards(amount);
                try {

                    await executeTransaction({
                        message: 'Sending Funds',
                        tx
                    });

                } catch (error) {
                    console.log(error);
                }

                setShowFundingModal(false);
            }
        });

        setShowFundingModal(true);
    }, [setModalStatus, setShowFundingModal, vault]);


    return (<>
        <Row>
            <Col md={6}>
                <h1>Vault Manager</h1>
                <p className="banner-info mt-4">
                    Please ensure that the vault address <strong className='text-primary-gradient'> {address} </strong>  is excluded from any transaction mechanics such as fees, rewards or reflections and that it can freely accept the presale supply.
                </p>

                {vault && vault.whitelisted && <Col md={6}>
                    <h3>Restrictions</h3>
                    <p>{openForAll ? "Open For All" : "Only whitelisted addresses can stake"}</p>
                    <Button onClick={toggleOpenForAll} disabled={!connected || !isBsc || !!pendingTransaction}
                        className="btn text-white">{openForAll ? "Restrict" : "Open For All"}</Button>
                </Col>}
                <Col md={6}>
                    <h3 className='mt-4'>Funding Status</h3>

                    {vault && !vault.started ?
                        <>
                            <p>Top Up Vault</p>

                            <Button onClick={handleFunding} disabled={!connected || !isBsc || !!pendingTransaction} className="btn text-white">Top Up</Button>

                            <p>Start Vault</p>
                            <Button onClick={handleStart} disabled={!connected || !isBsc || !!pendingTransaction || vault.rewardSupply === 0} className="btn text-white">Start</Button>
                        </> : "Vault already started"
                    }
                </Col>

            </Col>


            {vault && vault.whitelisted && <Col md={6}>
                <Whitelist address={vault.address}></Whitelist>
            </Col>}
        </Row>

        <Modal
            className="stake-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={!!showFundingModal} onHide={handleClose} >

            <Modal.Body>
                <FundingInput vault={vault} address={address} {...modalStatus}></FundingInput>
            </Modal.Body>

        </Modal>
    </>);
}

export default AdminPanel;
