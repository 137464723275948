
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import VaultsData from "hpay/content/Vaults.json";
import Telegram from '../../../images/inline/telegram-icon.inline.svg';
import Twitter from '../../../images/inline/twitter.inline.svg';
import Reddit from '../../../images/inline/reddit.inline.svg';

import { useCurrentVault } from '../../../state/simple-staking';
import NumberFormat from 'react-number-format';
import { useVaultAddress } from '../../../utils/utils';

function VaultHeader({ address }) {
    const vaultAddress = useVaultAddress();
    const { currentVault: vaultData } = useCurrentVault(vaultAddress);

    const [vault, setVault] = useState(null);
    const [maxWallets, setMaxWallets] = useState(null);
    const [blocksLeft, setBlocksLeft] = useState();
    const [stakeTax, setStakeTax] = useState();
    const [unstakeTax, setUnstakeTax] = useState();

    const [apr, setApr] = useState(null);

    useEffect(() => {
        setVault(VaultsData[address]);
    }, [address]);

    useEffect(() => {
        if (!vaultData) {
            return;
        }
        setApr(numeral(vaultData.return).format('0,0'));
        setMaxWallets(vaultData.maxLock / vaultData.maxLockPerWallet);
        setBlocksLeft(vaultData.blocksLeft);
        setStakeTax(vaultData.stakeTax);
        setUnstakeTax(vaultData.unStakeTax);
    }, [vaultData]);


    const SocialMedia = <div className='ml-auto align-self-start'>
        <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-telegram`}>
                    <strong>Telegram</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={vault && vault.telegram}>
                <Telegram className="footer-icon" />
            </a>
        </OverlayTrigger>

        {vault && vault.reddit && <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-telegram`}>
                    <strong>Reddit</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={vault && vault.reddit}>
                <Reddit className="footer-icon" />
            </a>
        </OverlayTrigger>}

        <OverlayTrigger
            overlay={
                <Tooltip id={`tooltip-twitter`}>
                    <strong>Twitter</strong>.
                </Tooltip>
            }>
            <a target="_blank" rel="noopener noreferrer"
                href={vault && vault.twitter}>

                <Twitter className="footer-icon" />
            </a>
        </OverlayTrigger>
    </div>;

    return (
        <div className="reward-claim-component">
            <Row className="mb-lg-5 mb-4 reward-header">
                <Col md={8} lg={9}>

                    <div className="d-flex justify-content-between">
                        <div className='d-flex align-items-center'>
                            <img
                                style={{ borderRadius: '50%', alignSelf: 'baseline', marginTop: '5%', marginRight: '0.5rem' }}
                                src={'/vaults/' + (vault && vault.imagePath)}

                                width={60}
                                height={60}
                            />
                            <h2 style={{ lineHeight: '0.8' }} className="title-seperator ml-2">{vault && vault.owner} <br />
                                <small className='text-primary-gradient' style={{ fontSize: '1.2rem' }}>Simple Staking</small></h2>

                        </div>
                        <div className='d-flex flex-column justify-content-left text-center'>
                            <a target="_blank" rel="noopener noreferrer" className='mb-2'
                                href={vault && vault.website}>
                                Visit Website
                            </a>
                            {SocialMedia}
                        </div>
                    </div>
                    <Col md={12}>
                        <p className='text-justify '>
                            {vault && vault.description}
                        </p>
                    </Col>
                </Col>

                <Col md={4} lg={3} className=" d-flex align-items-center  justify-content-end text-center bounty-widget flex-wrap m-3 m-md-0">

                    <Col xs={12} sm={4} md={6} className="text-center text-md-right">
                        <small className="farming-stats-value-label">Blocks Left</small>
                        <p className="farming-stats-value-item text-primary-gradient">
                            <NumberFormat value={blocksLeft} displayType={'text'} thousandSeparator={true} />
                        </p>
                    </Col>


                    {stakeTax > 0 && <Col xs={12} sm={4} md={6} className="text-center text-md-right">
                        <small className="farming-stats-value-label">Stake Tax</small>
                        <p className="farming-stats-value-item text-primary-gradient">
                            <NumberFormat value={stakeTax} displayType={'text'} thousandSeparator={true} />%
                        </p>
                    </Col>}


                    {unstakeTax > 0 && <Col xs={12} sm={4} md={6} className="text-center text-md-right">
                        <small className="farming-stats-value-label">Unstake Tax</small>
                        <p className="farming-stats-value-item text-primary-gradient">
                            <NumberFormat value={unstakeTax} displayType={'text'} thousandSeparator={true} /> %
                        </p>
                    </Col>}

                </Col>
            </Row>
        </div>
    );
}

export default VaultHeader;
