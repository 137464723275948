import { Link, useParams } from '@reach/router';
import React from 'react';
import { Col, Row } from "react-bootstrap";
import { useCurrentVault, useUserIsAdmin } from '../../../state/simple-staking';
import { useVaultAddress } from '../../../utils/utils';
import AdminPanel from "./admin/admin-panel";
import VaultHeader from "./simple-vault-header";
import VaultInfo from "./simple-vault-info";
import VaultStats from "./simple-vault-stats";


function Vault() {
    const params = useParams();
    const vaultAddress = useVaultAddress();

    const { currentVault: vault } = useCurrentVault(vaultAddress);
    const { isAdmin } = useUserIsAdmin(vaultAddress);
    return (<>
        <Row className="mb-5 ">
            <Col md={12} className="mb-md-5 mb-4">
                <VaultHeader address={params.vaultAddress}></VaultHeader>
            </Col>

            <Col lg={6} xl={6} >
                <VaultStats address={params.vaultAddress} ></VaultStats>
            </Col>

            <Col lg={6} xl={6} >
                <VaultInfo address={params.vaultAddress} ></VaultInfo>
            </Col>

            {vault && vault.hpayFee > 0 && <Col md={12} className='text-center mb-2 mt-2'>
                <small>  * Hold <Link target='_blank' to="/invest/">
                    <span className='text-primary-gradient font-weight-bold'> HPAY </span>
                </Link> in your wallet to and get a {vault.hpayFee}% staking tax discount.</small>
            </Col>}
        </Row>
        {
            vault && isAdmin && <Row className="mb-2 mt-5 ">
                <AdminPanel {...vault}> </AdminPanel>
            </Row>
        }
    </>
    );
}

export default Vault;
