
import { navigate } from "gatsby";
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import ChainIcon from '../../../images/chain-icon.png';
import { useCurrentVault, useVaultData } from '../../../state/flash-staking';

const statusMapping = {
    0: "Upcoming",
    1: "Staking Live",
    2: "Round Started",
    3: "Ended"
};


function Vault({ name, address, imagePath, typeDescription, location }) {

    const vaultData = useVaultData(address);
    const [status, setStatus] = useState();

    useEffect(() => {
        if (!vaultData) {
            return;
        }
        setStatus(statusMapping[vaultData.status]);

    }, [vaultData, setStatus]);
    return (
        <>
            <div className="staking-component h-100">
                <Card>
                    <Card.Body>
                        <Row style={{ marginBottom: '1.25rem' }}>
                            <Col className="d-flex align-items-center col-auto">
                                <img
                                    style={{ borderRadius: '50%' }}
                                    src={'/vaults/' + imagePath}
                                    alt={`${name} vault`}
                                    width={40}
                                    height={40}
                                />
                                <div className="mb-2 text-sm-left text-lg-left text-md-center text-center ml-3">
                                    <h6 className="mb-0" style={{ lineHeight: 1.2 }}>{name} </h6>
                                    <small>{typeDescription}</small>
                                </div>
                            </Col>
                            <Col className="d-flex align-items-start justify-content-end">
                                <Badge pill variant={'primary'} >
                                    <span className="text-primary-gradient" >
                                        Flash Staking
                                    </span>
                                </Badge>
                            </Col>
                        </Row>
                        <div>
                            <Row noGutters>

                                <Col className="farming-stats-group text-left">
                                    <small className="farming-stats-value-label">Chain</small>
                                    <p className="farming-stats-value-item"><img src={ChainIcon} alt="icon" /> BSC </p>
                                </Col>
                                <Col className="farming-stats-group text-right">
                                    <small className="farming-stats-value-label">APR</small>
                                    <p className="farming-stats-value-item">
                                        <small>%</small><CountUp isCounting end={(vaultData && vaultData.return) || 0} decimals={2} preserveValue={true} separator=',' />
                                    </p>
                                </Col>
                            </Row>

                            <Row noGutters>
                                <Col className="farming-stats-group text-left">
                                    <small className="farming-stats-value-label">Status </small>
                                    <p className="farming-stats-value-item">
                                        {status}
                                    </p>
                                </Col>

                                <Col className="farming-stats-group text-right">
                                    <small className="farming-stats-value-label">Rewards </small>
                                    <p className="farming-stats-value-item">
                                        <CountUp isCounting end={(vaultData && vaultData.totalRewardFund) || 0} decimals={0} preserveValue={true} separator=',' />

                                    </p>
                                </Col>
                            </Row>
                            <Row className="justify-content-between align-items-center mt-2">
                                <Col sm={12} className="mb-sm-0 mb-3">
                                    <Button onClick={
                                        () => {
                                            navigate("/vaults/" + location);
                                        }}
                                        // disabled={!connected || !isBsc || !!pendingTransaction}
                                        className="text-white" block>View Vault</Button>
                                </Col>

                            </Row>
                        </div>
                    </Card.Body>
                </Card >
            </div>
        </>
    );
}

export default Vault;
